body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.buttonGroup {
    flex-direction: row;
    display: flex;
    gap: 2rem;
}
@media (max-width: 768px) {
  .titleStroke {
    font-size: 3rem !important;
  }
  .topText {
    width: 100% !important;
  }
  .orderbookHeader {
    width: 100% !important;
  }
  .buttonGroup {
    flex-direction: column;
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
  .horizontalScrollContainer {
    height: 40vh !important;
  }
  .heroButtons {
    display: none !important;
  }
}
