.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
    border: 0.1rem solid transparent;
    border-radius: 0.5rem;
    padding: 0.4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    max-height: 2.6rem;
    height: 2.4rem;
}
.buttonText {
    font-size: 1.2rem;
}
@media (max-width: 768px) {
    .buttonContainer {
        width: 100%;
    }
}
