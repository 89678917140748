.orderbookContainer {
    padding: 2rem;
    border-radius: 0.5rem;
    border: 0.1rem solid var(--color-pink);
    width: 80vw;
    background-color: var(--color-purple-dark);
}
.orderbookHorizontal {
    display: flex;
    flex-direction: row;
    background-color: var(--color-purple-dark);
    color: var(--color-pink);
    /* column-gap: 0.4rem; */
}
.orderbookHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.orderbookLine {
    position: relative;
    display: flex;
    height: 40px;
}
.orderbookLineProgressLeft {
    position: absolute;
    display: flex;
    overflow: hidden;
    background-color: var(--color-green-shade);
    color: var(--color-pink);
    height: 40px;
    right: 0;
}
.orderbookLineProgressRight {
    position: absolute;
    left: 0;
    display: flex;
    overflow: hidden;
    background-color: var(--color-pink-shade);
    height: 40px;
}
.orderbookLineProgressTextLeft {
    position: absolute;
    display: flex;
    border-radius: 0.4rem;
    height: 40px;
    left: 0;
    align-items: center;
    /* padding-right: 2rem; */

}
.orderbookLineProgressTextRight {
    position: absolute;
    right: 0;
    display: flex;
    border-radius: 0.4rem;
    overflow: hidden;
    height: 40px;
    align-items: center;
}
.orderbook {
    position: relative;
    display: flex;
    border-radius: 0.4rem;
    overflow: hidden;
    background-color: var(--color-purple-dark);
}
.orderbookList {
    display: flex;
    flex-direction: column;
}
.orderbookRowList {
    width: 100%;
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
}
.orderbookItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}
.orderbookItemLeft {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: var(--color-pink);
    font-size: 1.1rem;
}
.orderbookItemCenter {
    display: flex;
    flex-direction: row;
    color: var(--color-pink);
    justify-content: center;
}
.orderbookItemRight {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: var(--color-pink);
    font-size: 1.1rem;
}
.spacerLine {
    background-color: var(--color-pink);
}

.headerText {
    font-weight: 800;
    font-size: 0.8rem;
    color: var(--color-pink);
    margin: 0px;
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .orderbookContainer {
        width: 70vw;
    }
}
