.horizontalScrollContainer {
    height: 50vh;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #0B1729;
    overflow: scroll;
    width: 100vw;
    flex-direction: row;
    display: flex;
    gap: 4rem;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.horizontalScrollContainer::-webkit-scrollbar {
    display: none;
}
