.converterContainer {
    position: relative;
    padding: 2rem;
    border-radius: 0.5rem;
    border: 0.1rem solid var(--color-pink);
    width: 80vw;
    background-color: var(--color-purple-dark);
}
.converterInput {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 4rem;
}
.spacerLine {
    background-color: var(--color-pink);
}
.label {
    font-size: 1rem;
    color: var(--color-yellow);
    margin-bottom: 1rem;
    color: #fff;
    margin: 0;
    padding: 0;
    font-weight: 600;
}
.input {
    font-size: 1.6rem;
    width: 100%;
    height: 100%;
    color: #fff;
    background-color: transparent;
    border: none;
    outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.convertButton {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    width: 120px;
    height: 100%;
}
.button {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    border: 0.1rem solid transparent;
    border-radius: 50px;
    height: 50px;
    width: 50px;
    background-color: var(--color-pink);
    border: 0.8rem solid var(--color-purple-dark);
}

@media (max-width: 768px) {
    .converterContainer {
        width: 70vw;
    }
}
