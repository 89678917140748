.dividerWrapper {
    margin-bottom: -0.4rem;
}

.custom-shape-divider-bottom-1714423813 {
    margin-top: -16rem;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1714423813 svg {
    position: relative;
    display: block;
    width: calc(141% + 1.3px);
    height: 267px;
}

.custom-shape-divider-bottom-1714423813 .shape-fill {
    fill: #0B1729;
}
