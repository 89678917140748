.container {
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: rgb(11,23,41);
    background: linear-gradient(180deg, var(--color-purple-dark) 26%, rgba(216,38,85,1) 89%);
}
.topCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.topTitle {
    font-size: 1.6rem;
    font-weight: 500;
    color: #fff;
    text-align: center;
    margin-top: 2rem;
    width: 80%;
}
.topSubtitle {
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
    text-align: center;
    margin-top: 2rem;
    align-items: center;
    width: 80%;
}
.topText {
    width: 70vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.topImage {
    margin-top: 2rem;
    z-index: 10;
}
.heroButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    gap: 1rem;
    width: 100%;
    margin-top: 2rem;
}

.footer {
    height: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.footerLink {
    color: var(--color-purple-dark);
    font-size: 1rem;
    margin: 0;
    padding: 0;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.3s;
    opacity: 0.5;
    border-right: 2px solid var(--color-purple-dark);
    padding-right: 0.4rem;
    padding-left: 0.4rem;
}
.footerLink:last-child {
    border-right: none;
}
.footerLink:hover {
    opacity: 1;
}
.copyrightText {
    color: #fff;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 600;
}
