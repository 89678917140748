.listContainer {
    width: 80vw;
}

.progressbarContainer {
    position: relative;
    height: 2rem;
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
    display: flex;
    align-items: center;
}
.progressbarLabel {
    position: absolute;
    height: 2rem;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding-right: 1rem;
    z-index: 99;
}
.progressbarProgress {
    position: absolute;
    border-radius: 1rem;
    height: 2rem;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
}
