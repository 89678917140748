.tokenomicsContainer {
    width: 80vw;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4rem;
}
.tokenomicsTitle {
    color: #fff;
    font-size: 1.4rem;
    font-weight: 600;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
-tokenomicsTitle::-webkit-scrollbar {
    display: none;
}
.tokenomicsSubtitle {
    color: #fff;
    font-size: 0.8rem;
    font-weight: 800;
    text-transform: uppercase;
}
.qrComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@media (max-width: 768px) {
    .tokenomicsContainer {
      flex-direction: column;
      word-break: break-all;
    }
    .qrComponent {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}
