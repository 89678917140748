.cardContainer {
    border-radius: 1rem;
    background-color: var(--color-purple-dark);
    padding: 2rem;
    min-width: 400px;
    height: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    transition: 0.3s;
}
.cardContainer:first-child {
    margin-left: 4rem
}
.cardContainer:last-child {
    margin-right: 4rem
}
.cardContainer:hover {
    transform: scale(1.04);
}
.cardRight {
    gap: 0px;
    margin-left: 1rem;
}
.cardTitle {
    font-size: 3rem;
    margin: 0px;
    color: var(--color-pink);
    font-weight: 600;
}
.cardText {
    font-size: 1.4rem;
    color: var(--color-pink);
    margin: 0px;
}
.checkmark {
    position: absolute;
    top: 2rem;
    right: 2rem;
    height: 30px;
    width: 30px;
}

@media (max-width: 768px) {
    .cardContainer:first-child {
        margin-left: 2rem;
    }
    .horizontalScrollContainer {
        gap: 2rem !important;
    }
    .cardContainer {
        padding: 1rem;
        min-width: 60vw !important;
        height: 160px;
    }
    .cardContainer .titleStroke {
        font-size: 5rem !important;
    }
    .cardTitle {
        font-size: 2rem;
    }
    .cardText {
        font-size: 1.2rem;
    }
    .checkmark {
        top: 0.5rem;
        right: 0.5rem;
    }
}
