:root {
    --color-yellow: #F1CC6F;
    --color-green: rgba(38, 216, 155, 1);
    --color-green-shade: rgba(38, 216, 155, 0.1);
    --color-purple: #0B1729;
    --color-purple-dark: #091220;
    --color-pink: #d82655;
    --color-pink-shade: rgba(216,38,85, 0.1);
    --color-pink-transparent: rgba(216,38,85,0.4);
}
body {
    background-color: var(--color-purple)
}
.bold {
    font-weight: bold;
}
.sectionSubtitle {
    color: #fff;
    font-weight: 600;
    font-size: 1.6rem;
    display: flex;
    width: 50vw;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0;
}
.sectionText {
    color: #fff;
    font-size: 1.2rem;
    display: flex;
    width: 80vw;
    justify-content: flex-start;
    flex-direction: column;
}
