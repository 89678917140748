.heroContainer {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: rgb(11,23,41);
    background-size: contain;
    background-attachment: fixed;
    /* background-image: url("../../../public/images/hero_backdrop.png"), linear-gradient(180deg, rgba(11,23,41,1) 26%, rgba(216,38,85,1) 89%); */
    background-image: url("../../../public/images/hero_backdrop.png"), linear-gradient(180deg, rgba(11,23,41,1) 26%, rgba(216,38,85,1) 89%);
    background-image: url("../../../public/images/hero_backdrop.png"), -webkit-linear-gradient(180deg, rgba(11,23,41,1) 26%, rgba(216,38,85,1) 89%);
    background-image: url("../../../public/images/hero_backdrop.png"), linear-gradient(180deg, rgba(11,23,41,1) 26%, rgba(216,38,85,1) 89%);
    background: linear-gradient(180deg, rgba(11,23,41,1) 26%, rgba(216,38,85,1) 89%);
}
.heroBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    flex: 1;
    z-index: 1;
    width: 100%;
    height: 100%;
    /* background-color: red; */
    background-size: contain;
    background-attachment: fixed;
    background-image: url("../../../public/images/hero_backdrop.png");
}
.topCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
}
.topTitle {
    font-size: 1.6rem;
    font-weight: 500;
    color: #fff;
    text-align: center;
    margin-top: 2rem;
    width: 80%;
    z-index: 2;
}
.topSubtitle {
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
    text-align: center;
    margin-top: 2rem;
    align-items: center;
    width: 100%;
    z-index: 2;
}
.topText {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
}
.topImage {
    margin-top: 2rem;
    z-index: 10;
    z-index: 2;
}
.heroButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    gap: 1rem;
    width: 100%;
    margin-top: 2rem;
    z-index: 2;
}

/* Animate the token image on hover */
@keyframes pulse {
    0%,
    100% {
      transform: scale(var(--scale, 1));
      animation-timing-function: ease-in;
    }
    50% {
      transform: scale(var(--scale, 1.05));
    }
}
.tokenImage {
    transition: 0.4s;
    animation: pulse 4s infinite;
}
.tokenImage:hover {
    transform: scale(1.1);
}
